import React, { useEffect } from 'react'
import { AppStore, T, __ } from 'utils'
import { Icons } from './Icons'
import { Button } from './Button'
import { Box } from './Box'
import config from 'config'

interface Props {}

export const HeaderFloraButton = ({}: Props) => {
  useEffect(() => {
    if (AppStore.token) {
      document.cookie = `token=${AppStore.token}${config.floraCookieDomain ? `; domain=${config.floraCookieDomain}` : ''}`
    }
  }, [])

  const onOpenFloraModal = () => {
    return AppStore.openModal({
      id: 'flora-modal',
      body: (
        <Box width={1400} height={800} style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <iframe style={{width: '100%', height: '100%' }} src={config.floraUrl}></iframe>
        </Box>
      ),
      style: {
        minWidth: 1400,
        minHeight: 800,
      },
    })
  }
  return (
    <Button
      variant={'secondary-revert'}
      onClick={onOpenFloraModal} // da cambiare con il componente zapier
      size="smaller"
      style={{ width: 189, height: 40, fontWeight: 500 }}
    >
      <Icons.autopilotElectric style={{ marginRight: 4 }} /> {T.headerFloraModal.name}
    </Button>
  )
}
