import { useUserStore } from 'stores/UserStore'
import { isVisible, RemoteConfigValues } from 'utils/CheckVisibleElement'
import { Box } from './Box'
import { HeaderAutopilotButton } from './HeaderAutopilotButton'
import { HeaderButtonAccount } from './HeaderButtonAccount'
import NotifyOnHeader from './headerComponents/NotifyOnHeader'
import { HeaderPartnersButton } from './HeaderPartnersButton'
import { HeaderUpgradeButton } from './HeaderUpgradeButton'
import { HeaderFloraButton } from './HeaderFloraButton'

interface Props {
  title?: string
}

export const HeaderAllButtons = ({ title }: Props) => {
  const { currentUser } = useUserStore((s) => ({
    currentUser: s.user,
  }))

  const userEnableToUpgrade = !currentUser?.enterprise && !currentUser?.owner

  return (
    <Box row vcenter style={{ zIndex: 1, gap: 12 }}>
      {/* -------------- BUTTON AUTOPILOT -------------- */}
      <HeaderFloraButton />

      {/* -------------- BUTTON Upgrade -------------- */}
      {userEnableToUpgrade && <HeaderUpgradeButton />}

      {/* -------------- BUTTON AUTOPILOT -------------- */}
      {isVisible(RemoteConfigValues.AutopilotButtonDisabled) && <HeaderAutopilotButton />}

      {/* -------------- BUTTON PARTNERS -------------- */}
      {isVisible(RemoteConfigValues.HeaderIframePartnersDisabled) && <HeaderPartnersButton />}

      {/* -------------- BUTTON NOTIFICATION -------------- */}
      {<NotifyOnHeader />}

      {/* <HeaderButtonSettings /> */}

      <HeaderButtonAccount />
    </Box>
  )
}
